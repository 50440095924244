import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import lead from 'src/modules/lead/leadReducers';
import applicant from 'src/modules/applicant/applicantReducers';
import product from 'src/modules/product/productReducers';
import supplier from 'src/modules/supplier/supplierReducers';
import sheet from 'src/modules/sheet/sheetReducers';
import job from 'src/modules/job/jobReducers';
import { combineReducers } from 'redux';
import plan from 'src/modules/plan/planReducers';
import campaign from 'src/modules/campaign/campaignReducers';
import column from 'src/modules/userColumn/userColumnReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    lead,
    product,
    applicant,
    job,
    supplier,
    sheet,
    campaign,
    column
  });
