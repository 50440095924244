import list from 'src/modules/sheet/list/sheetListReducers';
import form from 'src/modules/sheet/form/sheetFormReducers';
import view from 'src/modules/sheet/view/sheetViewReducers';
import destroy from 'src/modules/sheet/destroy/sheetDestroyReducers';
import importerReducer from 'src/modules/sheet/importer/sheetImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
