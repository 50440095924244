import { CloseOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import React, { useState } from 'react';
import { i18n } from 'src/i18n';
import FormWrapper, { formItemLongLayout, tailFormItemLongLayout } from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import { yupResolver } from '@hookform/resolvers';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import RadioFormItem from 'src/view/shared/form/items/RadioFormItem';
import { NOTES_TYPE_OPTIONS } from 'src/modules/common/constants';

const schema = yup.object().shape({
  keyName: yupFormSchemas.string(i18n('sheet.fields.keyName'), {
    required: true,
    min: 2,
    max: 255,
  }),
  type: yupFormSchemas.string(i18n('sheet.fields.type'), {
    required: true,
  }),
  description: yupFormSchemas.string(i18n('sheet.fields.description'), {
    max: 21845,
  }),
});

const SheetForm = (props) => {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      keyName: record.keyName,
      description: record.description,
      type: record.type,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const onSubmit = (values) => {
    props.onSubmit(props?.record?.id, values);
  };

  const { saveLoading } = props;
  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <InputFormItem
            name="keyName"
            label={i18n('sheet.fields.keyName')}
            required={true}
            layout={formItemLongLayout}
            autoFocus
          />
          <RadioFormItem
            label={'Typ'}
            name="type"
            required={true}
            layout={formItemLongLayout}
            options={NOTES_TYPE_OPTIONS}
            isTypedisabled={props.isTypedisabled}
          />

          <TextAreaFormItem
            name="description"
            label={i18n('sheet.fields.description')}
            required={false}
            layout={formItemLongLayout}
          />

          <Form.Item className="form-buttons" {...tailFormItemLongLayout}>
            <Button loading={saveLoading} type="primary" onClick={form.handleSubmit(onSubmit)} icon={<SaveOutlined />}>
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} icon={<UndoOutlined />}>
              {i18n('common.reset')}
            </Button>

            {props.onCancel && (
              <Button disabled={saveLoading} onClick={() => props.onCancel()} icon={<CloseOutlined />}>
                {i18n('common.cancel')}
              </Button>
            )}
          </Form.Item>
        </form>
        Hinweis: Dieses Eigenschaftsfeld wird für alle Kontakte erstellt.
      </FormProvider>
    </FormWrapper>
  );
};

export default SheetForm;
