import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Collapse, Row } from 'antd';
import './collapsable.scss';

const { Panel } = Collapse;

interface CollapsableCardProps {
  panelKey: string;
  children: ReactNode;
  title: string;
  icon: ReactNode;
  extra?: ReactNode;
}

const CollapsableCard: FC<CollapsableCardProps> = ({ children, title, icon, extra, panelKey }) => {
  const getInitialState = () => {
    const storedState = localStorage.getItem('collapsible-panels');
    const state = storedState ? JSON.parse(storedState) : {};
    return state[panelKey] !== undefined ? state[panelKey] : [panelKey];
  };

  const [activeKeys, setActiveKeys] = useState<string[]>();

  useEffect(() => {
    setActiveKeys(getInitialState());
  }, []);
  // Save the state to local storage whenever it changes
  useEffect(() => {
    const storedState = localStorage.getItem('collapsible-panels');
    const state = storedState ? JSON.parse(storedState) : {};
    state[panelKey] = activeKeys;
    localStorage.setItem('collapsible-panels', JSON.stringify(state));
  }, [activeKeys, panelKey]);

  const onChange = (key: string | string[]) => {
    setActiveKeys(Array.isArray(key) ? key : [key]);
  };

  return (
    <Collapse
      className="parent"
      bordered={false}
      expandIconPosition="right"
      activeKey={activeKeys}
      onChange={onChange}
      accordion
    >
      <Panel
        extra={extra}
        className="custom-pannel"
        header={
          <Row align="middle" style={{ gap: 10, flex: 1 }}>
            {icon} <span className="title">{title}</span>
          </Row>
        }
        key={panelKey}
      >
        {children}
      </Panel>
    </Collapse>
  );
};

export default CollapsableCard;
