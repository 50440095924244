import list from 'src/modules/job/list/jobListReducers';
import form from 'src/modules/job/form/jobFormReducers';
import view from 'src/modules/job/view/jobViewReducers';
import destroy from 'src/modules/job/destroy/jobDestroyReducers';
import importerReducer from 'src/modules/job/importer/jobImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
