import styled from 'styled-components';

const QRCodeWrapper = styled.div`
  .button-wrap {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
  }

  .highlight {
    border: 1px solid var(--antd-wave-shadow-color);
  }
  .not-highlight {
    border: 1px solid gray;
  }
  .qrcode-button {
    cursor: pointer;
    height: 30px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .qrcode {
    position: absolute;
    animation: fadeCn 0.5s;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    top: 64px;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: transform 0.5s;
    background-size: contain;
  }
  @keyframes fadeCn {
    0% {
      opacity: 0;
      transform: scale(0.5) translate(-100px, -100px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translate(0px, 0px);
    }
  }
`;

export default QRCodeWrapper;
