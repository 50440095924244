import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import leadEnumerators from 'src/modules/lead/leadEnumerators';

export default [
  {
    name: 'firstName',
    label: i18n('entities.lead.fields.firstName'),
    schema: schemas.string(i18n('entities.lead.fields.firstName'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'lastName',
    label: i18n('entities.lead.fields.lastName'),
    schema: schemas.string(i18n('entities.lead.fields.lastName'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'lastContact',
    label: i18n('entities.lead.fields.lastContact'),
    schema: schemas.date(i18n('entities.lead.fields.lastContact'), {}),
  },
  {
    name: 'followUp',
    label: i18n('entities.lead.fields.followUp'),
    schema: schemas.date(i18n('entities.lead.fields.followUp'), {}),
  },
  {
    name: 'dealStage',
    label: i18n('entities.lead.fields.dealStage'),
    schema: schemas.enumerator(i18n('entities.lead.fields.dealStage'), {
      required: true,
      options: leadEnumerators.dealStage,
    }),
  },
  {
    name: 'company',
    label: i18n('entities.lead.fields.company'),
    schema: schemas.string(i18n('entities.lead.fields.company'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'street',
    label: i18n('entities.lead.fields.street'),
    schema: schemas.string(i18n('entities.lead.fields.street'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'country',
    label: i18n('entities.lead.fields.country'),
    schema: schemas.enumerator(i18n('entities.lead.fields.country'), {
      options: leadEnumerators.country,
    }),
  },
  {
    name: 'postalCode',
    label: i18n('entities.lead.fields.postalCode'),
    schema: schemas.integer(i18n('entities.lead.fields.postalCode'), {
      min: 1,
      max: 99999,
    }),
  },
  {
    name: 'city',
    label: i18n('entities.lead.fields.city'),
    schema: schemas.string(i18n('entities.lead.fields.city'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'phone',
    label: i18n('entities.lead.fields.phone'),
    schema: schemas.string(i18n('entities.lead.fields.phone'), {}),
  },
  {
    name: 'email',
    label: i18n('entities.lead.fields.email'),
    schema: schemas.string(i18n('entities.lead.fields.email'), {}),
  },
  {
    name: 'product',
    label: i18n('entities.lead.fields.product'),
    schema: schemas.relationToOne(i18n('entities.lead.fields.product'), {}),
  },
  {
    name: 'owner',
    label: i18n('entities.lead.fields.owner'),
    schema: schemas.relationToOne(i18n('entities.lead.fields.owner'), {
      required: true,
    }),
  },
  {
    name: 'source',
    label: i18n('entities.lead.fields.source'),
    schema: schemas.enumerator(i18n('entities.lead.fields.source'), {
      options: leadEnumerators.source,
    }),
  },
  {
    name: 'budget',
    label: i18n('entities.lead.fields.budget'),
    schema: schemas.integer(i18n('entities.lead.fields.budget'), {}),
  },
  {
    name: 'secondDecisionMaker',
    label: i18n('entities.lead.fields.secondDecisionMaker'),
    schema: schemas.string(i18n('entities.lead.fields.secondDecisionMaker'), {
      min: 2,
      max: 255,
    }),
  },
];
