import TenantService from 'src/modules/tenant/tenantService';
import selectors from 'src/modules/tenant/dashboard/tenantDashboardSelectors';
import Errors from 'src/modules/shared/error/errors';
import { get } from 'lodash';

const prefix = 'TENANT_DASHBOARD';

const actions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  COUNT_CHANGED: `${prefix}_COUNT_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  doChangeSelected(payload) {
    return {
      type: actions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: actions.RESETED,
    });

    dispatch(actions.doFetch());
  },

  doChangeCount: (count) => (dispatch) => {
    dispatch({
      type: actions.COUNT_CHANGED,
      payload: { count },
    });
  },

  doChangePaginationAndSort: (pagination, sorter) => async (dispatch, getState) => {
    dispatch({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch({
      type: actions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(actions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    dispatch(actions.doFetch(filter, true));
  },

  doFetch:
    (filter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.FETCH_STARTED,
          payload: { filter, keepPagination },
        });

        const response = await TenantService.stats(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: actions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: get(response, 'totalCount[0].count', 0),
          },
        });
        return response.count;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FETCH_ERROR,
        });
      }
    },
};

export default actions;
