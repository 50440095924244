import React, { useState } from 'react';
import { Modal } from 'antd';
import { i18n } from 'src/i18n';
import DetailsTable from './DetailsTable';


const LeadFormModal = (props) => {
    if (!props.visible) {
        return null;
    }

    return (
        <Modal
            style={{ top: 24 }}
            title={i18n('common.notesHistory.title')}
            visible={props.visible}
            onCancel={() => props.onCancel()}
            footer={false}
            width="40%"


        >
            <DetailsTable data={props.data} />
        </Modal>
    );
};

export default LeadFormModal;
