import React from 'react';
import { i18n } from 'src/i18n';
import { Button, Modal, Typography, Tag } from 'antd';
import { AD_BANNERS_MESSAGE } from 'src/modules/common/constants';
import { isDateSevenDaysOld } from 'src/modules/common/helper';

export const AdBannerModal = ({ isContacted, type, visible, onClose, onOk, contactedOn }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={
        !isContacted || isDateSevenDaysOld(contactedOn)
          ? [
              <Button type="primary" onClick={onOk}>
                Ja, gerne
              </Button>,
            ]
          : [<Tag color="success">{i18n('user.userBannerContactedSuccess')}</Tag>]
      }
    >
      <Typography.Paragraph style={{ userSelect: 'none' }}>{AD_BANNERS_MESSAGE[type]}</Typography.Paragraph>
    </Modal>
  );
};
