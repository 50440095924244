/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder: 'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      applicantCVs: {
        id: 'applicantCVs',
        folder: 'tenant/:tenantId/applicants/cvs',
        maxSizeInBytes: 1000 * 1024 * 1024,
        publicRead: true,
      },
      leadCVs: {
        id: 'leadCVs',
        folder: 'tenant/:tenantId/leads/cvs',
        maxSizeInBytes: 1000 * 1024 * 1024,
        publicRead: true,
      },
      supplierLogos: {
        id: 'supplierLogos',
        folder: 'tenant/:tenantId/suppliers/logos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      adBanners: {
        id: 'adBanners',
        folder: 'global/ads/banner',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      script: {
        id: 'script',
        folder: 'global/scripts',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
    };
  }
}
