import React, { FC } from 'react';

import './inputBox.css';
import {Input} from "antd";
import { SearchOutlined } from '@ant-design/icons';

interface props {
  returnText: (text: any) => void;
  onEnter?: (text: any) => void;
  initText?: string;
  searchButtonText?: string;
  placeholderText?: string;
  loading: boolean;
}

const { Search } = Input;

export const InputBox: FC<props> = ({ 
  returnText, 
  initText, 
  loading, 
  onEnter,
  placeholderText = '',
  searchButtonText = 'Search'
}) => {
  return (
    <div className="inputBox">
      {/* <Search placeholder="" onChange={(event) => returnText(event.target.value)} value={initText || ''}  loading={loading} enterButton="Search"/> */}
      <Search 
        prefix={<SearchOutlined />}
        placeholder={placeholderText}
        onChange={(event) => returnText(event.target.value)} 
        value={initText || ''}
        onSearch={(value) => onEnter && value && onEnter(value)} 
        loading={loading} 
        enterButton={searchButtonText}
      />
    </div>
  );
};
