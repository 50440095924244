import React, { useCallback, useEffect, useState } from 'react';
import { i18n } from 'src/i18n';
import QRCodeWrapper from 'src/view/layout/styles/QRCodeWrapper';

const QRCode = () => {
  const [showQR, setShowQR] = useState(false);

  const handleClick = useCallback(() => {
    showQR && setShowQR(false);
  }, [showQR, setShowQR]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <span style={{ display: 'flex', height: '100%', position: 'relative', zIndex:10}}>
      <QRCodeWrapper>
        <div className="button-wrap">
          <div className={`${showQR ? 'highlight' : 'not-highlight'} qrcode-button`} onClick={() => setShowQR(!showQR)}>
            <p style={{ lineHeight: 'normal', margin: 0 }}>{i18n('qrcode.toApp')}</p>
          </div>
        </div>
        {showQR && (
          <div
            className="qrcode"
            style={{
              backgroundImage: `url(/images/A_M_App.svg)`,
            }}
          />
        )}
      </QRCodeWrapper>
    </span>
  );
};

export default QRCode;
