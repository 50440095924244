import UserColumnService from 'src/modules/userColumn/userColumnService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'USER_COLUMN';

const actions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doFind: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.FIND_STARTED,
      });

      const record = await UserColumnService.find();

      dispatch({
        type: actions.FIND_SUCCESS,
        payload: record.keys || {},
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });
    }
  },

  doUpdate: (data) => async (dispatch) => {
    try {
      dispatch({
        type: actions.UPDATE_STARTED,
      });

      const record = await UserColumnService.update(data);

      dispatch({
        type: actions.UPDATE_SUCCESS,
        payload: record.keys || {},
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.UPDATE_ERROR,
      });
    }
  },
};

export default actions;
