import React, { EventHandler, FC, ReactChild, ReactChildren, useEffect, useRef, useState } from 'react';
import { Spinner } from '../spinner/spinner';
import './button.scss';

interface ButtonProps {
  type?: string;
  icon?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  disable?: boolean;
  isLoading?: boolean;
  onClick?: EventHandler<any>;
}

export const Button: FC<ButtonProps> = ({ children, type, isLoading, disable, onClick }) => {
  const [buttonStyle, setButtonStyle] = useState<string>();
  const [initIsLoading, setInitIsLoading] = useState<boolean>(false);
  const [initDisable, setInitDisable] = useState<boolean>(false);
  const styleStateRef = useRef<HTMLDivElement>(null);
  const node = styleStateRef.current as any;

  useEffect(() => {
    if (type !== undefined) {
      setButtonStyle(type);
    }
  }, [type]);

  useEffect(() => {
    if (isLoading !== undefined) {
      setInitIsLoading(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    switch (buttonStyle) {
      case 'primary':
        node?.classList.add('primary');
        break;
      case 'secondary':
        node?.classList.add('secondary');
        break;
      case 'outline':
        node?.classList.add('outline');
        break;
      case 'danger':
        node?.classList.add('danger');
        break;
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonStyle]);

  useEffect(() => {
    if(initIsLoading) {
      setInitDisable(true);
    } else {
      setInitDisable(false);
    }
  }, [initIsLoading])

  useEffect(() => {
    if (initDisable) {
      node?.classList.add('disable');
    } else {
      node?.classList.remove('disable');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initDisable]);

  useEffect(() => {
    if (disable !== undefined) {
      setInitDisable(disable);
    }
  }, [disable]);


  return (
    <div className="button" ref={styleStateRef} onClick={onClick}>
      { initIsLoading ? <span><Spinner /> {children}</span> : <span>{children}</span> }
    </div>
  );
};
