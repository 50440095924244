import list from 'src/modules/lead/list/leadListReducers';
import form from 'src/modules/lead/form/leadFormReducers';
import view from 'src/modules/lead/view/leadViewReducers';
import destroy from 'src/modules/lead/destroy/leadDestroyReducers';
import importerReducer from 'src/modules/lead/importer/leadImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
