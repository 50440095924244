import LeadService from 'src/modules/lead/leadService';
import selectors from 'src/modules/lead/list/leadListSelectors';
import { i18n } from 'src/i18n';
import exporterFields from 'src/modules/lead/list/leadListExporterFields';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

import moment from 'moment';

const prefix = 'LEAD_LIST';

const actions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  NUMBER_FETCH_STARTED: `${prefix}_NUMBER_FETCH_STARTED`,
  NUMBER_FETCH_SUCCESS: `${prefix}_NUMBER_FETCH_SUCCESS`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  DEALSTAGE_CHANGED: `${prefix}_DEALSTAGE_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doChangeSelected(payload) {
    return {
      type: actions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: actions.RESETED,
    });

    dispatch(actions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: actions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await LeadService.list(filter, selectors.selectOrderBy(getState()), null, null);
      response.rows = response.rows.map((row) => {
        row.id = `${row.firstName} ${row.lastName}`;
        let reason = '';
        if (row.dealStage === 'not_suitable' && row.notSuitableReason) {
          reason = row.notSuitableReason;
          if (reason) reason = ` (${i18n(`entities.lead.enumerators.not_suitable.${reason}`)})`;
        }
        row.dealStage = i18n(`entities.lead.enumerators.dealStage.${row.dealStage}`) + reason;

        return row;
      });
      new Exporter(exporterFields, i18n('entities.lead.exporterFileName')).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: actions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.EXPORT_ERROR,
      });
    }
  },

  doChangePaginationAndSort: (pagination, sorter) => async (dispatch, getState) => {
    dispatch({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch({
      type: actions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(actions.doFetchCurrentFilter());
  },

  doChangeLastDealstage: (dealstage) => async (dispatch, getState) => {
    dispatch({
      type: actions.DEALSTAGE_CHANGED,
      payload: dealstage,
    });
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    dispatch(actions.doFetch(filter, true));
  },

  doFetchFollowUpToday: () => async (dispatch, getState) => {
    const dateNow = moment(new Date()).format('YYYY-MM-DD');
    const filter = {
      followUpRange: [dateNow, dateNow],
    };
    dispatch(actions.doFetch(filter, true));
  },

  doFetch:
    (filter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.FETCH_STARTED,
          payload: { filter, keepPagination },
        });

        const response = await LeadService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: actions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FETCH_ERROR,
        });
      }
    },
  doFetchLeadAmount: (dealStage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.NUMBER_FETCH_STARTED,
      });

      const response = await LeadService.getNumberOfLeadsPerDealstage({
        dealStage: dealStage,
      });

      dispatch({
        type: actions.NUMBER_FETCH_SUCCESS,
        payload: {
          dealStageCount: response.dealsCount,
          budget: response.budget,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FETCH_ERROR,
      });
    }
  },
};

export default actions;
