const parseUrl = function (url: string) {
    try {
        const isTenant = url.split('/')[3] === 'tenant'
        const urlTenantId = url.split('/')[4]
        const path = url.split('tenant/')[1]
        if (!isTenant) return []
        let route = '/'
        if (path) {
            route = path.replace(urlTenantId, '');
        }
        return [urlTenantId, route]        
    } catch (error) {
        return []
    }
}
export default parseUrl;