import service from 'src/modules/auth/authService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import { AuthToken } from 'src/modules/auth/authToken';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import selectors from 'src/modules/auth/authSelectors';
import { tenantSubdomain } from '../tenant/tenantSubdomain';
import SettingsService from '../settings/settingsService';
import TenantService from '../tenant/tenantService';
import SiteSettingsService from '../siteSettings/siteSettingsService';
import { SiteSettings } from '../siteSettings';

const prefix = 'AUTH';

const actions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  ENABLE_MULTIFACTOR_AUTHENTICATOR: `${prefix}_MULTIFACTOR_AUTHENTICATOR`,
  ENABLE_MULTIFACTOR_EMAIL: `${prefix}_MULTIFACTOR_EMAIL`,
  
  CHANGING_TENANT_START: `${prefix}_CHANGING_TENANT_START`,
  CHANGING_TENANT_SUCCESS: `${prefix}_CHANGING_TENANT_SUCCESS`,
  CHANGING_TENANT_ERROR: `${prefix}_CHANGING_TENANT_ERROR`,

  TOGGLE_SUPER_ADMIN_PERMISSION: `${prefix}_TOGGLE_SUPER_ADMIN_PERMISSION`,

  UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

  PASSWORD_CHANGE_START: `${prefix}_PASSWORD_CHANGE_START`,
  PASSWORD_CHANGE_SUCCESS: `${prefix}_PASSWORD_CHANGE_SUCCESS`,
  PASSWORD_CHANGE_ERROR: `${prefix}_PASSWORD_CHANGE_ERROR`,

  CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
  CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
  CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

  PASSWORD_RESET_EMAIL_START: `${prefix}_PASSWORD_RESET_EMAIL_START`,
  PASSWORD_RESET_EMAIL_SUCCESS: `${prefix}_PASSWORD_RESET_EMAIL_SUCCESS`,
  PASSWORD_RESET_EMAIL_ERROR: `${prefix}_PASSWORD_RESET_EMAIL_ERROR`,

  PASSWORD_RESET_START: `${prefix}_PASSWORD_RESET_START`,
  PASSWORD_RESET_SUCCESS: `${prefix}_PASSWORD_RESET_SUCCESS`,
  PASSWORD_RESET_ERROR: `${prefix}_PASSWORD_RESET_ERROR`,

  EMAIL_VERIFY_START: `${prefix}_EMAIL_VERIFY_START`,
  EMAIL_VERIFY_SUCCESS: `${prefix}_EMAIL_VERIFY_SUCCESS`,
  EMAIL_VERIFY_ERROR: `${prefix}_EMAIL_VERIFY_ERROR`,

  EMAIL_CONFIRMATION_START: `${prefix}_EMAIL_CONFIRMATION_START`,
  EMAIL_CONFIRMATION_SUCCESS: `${prefix}_EMAIL_CONFIRMATION_SUCCESS`,
  EMAIL_CONFIRMATION_ERROR: `${prefix}_EMAIL_CONFIRMATION_ERROR`,

  EMAIL_SEND_START: `${prefix}_EMAIL_SEND_START`,
  EMAIL_SEND_SUCCESS: `${prefix}_EMAIL_SEND_SUCCESS`,
  EMAIL_SEND_ERROR: `${prefix}_EMAIL_SEND_ERROR`,

  ROUTE_CHANGED: `${prefix}_ROUTE_CHANGED`,

  doClearErrorMessage() {
    return {
      type: actions.ERROR_MESSAGE_CLEARED,
    };
  },

  doSendEmailConfirmation: () => async (dispatch, getState) => {
    try {
      dispatch({ type: actions.EMAIL_CONFIRMATION_START });
      await service.sendEmailVerification();
      Message.success(i18n('auth.verificationEmailSuccess'));
      dispatch({
        type: actions.EMAIL_CONFIRMATION_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: actions.EMAIL_CONFIRMATION_ERROR });
    }
  },

  doSendEmail: (body, callback) => async (dispatch, getState) => {
    try {
      dispatch({ type: actions.EMAIL_SEND_START });
      await service.sendEmail(body);
      Message.success(i18n('email.sendEmailSuccess'));
      dispatch({
        type: actions.EMAIL_SEND_SUCCESS,
      });
      if (callback) callback();
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: actions.EMAIL_SEND_ERROR });
    }
  },

  doSendPasswordResetEmail: (email) => async (dispatch) => {
    try {
      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_START,
      });
      await service.sendPasswordResetEmail(email);
      Message.success(i18n('auth.passwordResetEmailSuccess'));
      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.PASSWORD_RESET_EMAIL_ERROR,
      });
    }
  },

  doRegisterEmailAndPassword: (email, password) => async (dispatch) => {
    try {
      dispatch({ type: actions.AUTH_START });

      const token = await service.registerWithEmailAndPassword(email, password);

      AuthToken.set(token, true);

      const currentUser = await service.fetchMe();

      const adsData = await SiteSettingsService.find();
      SiteSettings.set(adsData);

      dispatch({
        type: actions.AUTH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: actions.AUTH_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSigninWithEmailAndPassword: (email, password, rememberMe, otpData = {}) => async (dispatch) => {
    try {
      dispatch({ type: actions.AUTH_START });

      let currentUser = null;

      const resp = await service.signinWithEmailAndPassword(email, password, otpData);

      const token = typeof resp === 'string' ? resp : resp.token
      if (token) {
        AuthToken.set(token, rememberMe);
        currentUser = await service.fetchMe();

        const adsData = await SiteSettingsService.find();
      	SiteSettings.set(adsData);

        dispatch({
          type: actions.AUTH_SUCCESS,
          payload: {
            currentUser,
          },
        });
        return;
      }

      if (resp.multiFactorEnabled.email && resp.multiFactorEnabled.authenticator) {
        dispatch({
          type: actions.ENABLE_MULTIFACTOR_AUTHENTICATOR,
        });
      } else if (resp.multiFactorEnabled.email) {
        dispatch({
          type: actions.ENABLE_MULTIFACTOR_EMAIL,
        });
      } else {
        throw new Error('Error on Sign In');
      }
    } catch (error) {
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: actions.AUTH_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSignout: () => async (dispatch) => {
    try {
      dispatch({ type: actions.AUTH_START });
      dispatch({
        type: actions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
        },
      });

      await service.signout();

      getHistory().push('/auth/signin')
      
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.AUTH_ERROR,
      });
    }
  },

  doInit: () => async (dispatch) => {
    try {
      const token = AuthToken.get();
      let currentUser = null;

      if (token) {
        currentUser = await service.fetchMe();

        const adsData = await SiteSettingsService.find();
        SiteSettings.set(adsData);
      }

      dispatch({
        type: actions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: actions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  doRefreshCurrentUser: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.CURRENT_USER_REFRESH_START,
      });

      let currentUser = null;
      const token = AuthToken.get();

      if (token) {
        currentUser = await service.fetchMe();

        const adsData = await SiteSettingsService.find();
        SiteSettings.set(adsData);
      }

      dispatch({
        type: actions.CURRENT_USER_REFRESH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: actions.CURRENT_USER_REFRESH_ERROR,
        payload: error,
      });
    }
  },

  doUpdateProfile: (data) => async (dispatch) => {
    try {
      dispatch({
        type: actions.UPDATE_PROFILE_START,
      });

      await service.updateProfile(data);

      dispatch({
        type: actions.UPDATE_PROFILE_SUCCESS,
      });
      await dispatch(actions.doRefreshCurrentUser());
      Message.success(i18n('auth.profile.success'));
      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.UPDATE_PROFILE_ERROR,
      });
    }
  },

  doChangePassword: (oldPassword, newPassword) => async (dispatch) => {
    try {
      dispatch({
        type: actions.PASSWORD_CHANGE_START,
      });

      await service.changePassword(oldPassword, newPassword);

      dispatch({
        type: actions.PASSWORD_CHANGE_SUCCESS,
      });
      await dispatch(actions.doRefreshCurrentUser());
      Message.success(i18n('auth.passwordChange.success'));
      getHistory().push('/lead');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.PASSWORD_CHANGE_ERROR,
      });
    }
  },

  doVerifyEmail: (token) => async (dispatch, getState) => {
    try {
      const isLoading = selectors.selectLoadingVerifyEmail(getState());

      if (isLoading) {
        return;
      }

      dispatch({
        type: actions.EMAIL_VERIFY_START,
      });

      await service.verifyEmail(token);

      await dispatch(actions.doRefreshCurrentUser());

      dispatch({
        type: actions.EMAIL_VERIFY_SUCCESS,
      });

      getHistory().push('/');
    } catch (error) {
      Errors.showMessage(error);
      dispatch({
        type: actions.EMAIL_VERIFY_ERROR,
      });
      getHistory().push('/');
    }
  },

  doResetPassword: (token, password) => async (dispatch) => {
    try {
      dispatch({
        type: actions.PASSWORD_RESET_START,
      });

      await service.passwordReset(token, password);

      Message.success(i18n('auth.passwordResetSuccess'));
      dispatch({
        type: actions.PASSWORD_RESET_SUCCESS,
      });
      getHistory().push('/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.PASSWORD_RESET_ERROR,
      });

      dispatch(actions.doSignout());
      getHistory().push('/');
    }
  },

  doSelectTenant: (tenant,route='/') => async (dispatch) => {
    if (tenantSubdomain.isEnabled) {
      tenantSubdomain.redirectAuthenticatedTo(tenant.url);
      return;
    }

    AuthCurrentTenant.set(tenant);
    await dispatch(actions.doRefreshCurrentUser());
    SettingsService.applyThemeFromTenant();
    getHistory().push(route);
    dispatch({
      type: actions.CHANGING_TENANT_SUCCESS,
    });
  },

  doSetTenantById: (tenantId,route) => async (dispatch) => {
    dispatch({
      type: actions.CHANGING_TENANT_START,
    });
    const tenant = await TenantService.find(tenantId);
    if (tenant) {
      dispatch(actions.doSelectTenant(tenant,route))
    }
  },

  doRouteChange: (status) => async (dispatch) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({
      type: actions.ROUTE_CHANGED,
      payload: {
        status,
      },
    });
  },
  
  doToggleSuperAdmin: (status) => async (dispatch) => {
    dispatch({
      type: actions.TOGGLE_SUPER_ADMIN_PERMISSION,
      payload: {
        status,
      },
    });
  }
};

export default actions;
