import styled from 'styled-components';

const FormImageWrapper = styled.div`
  margin-bottom: 10px;
  padding: 16px;
  padding-top: 0px;

  .header {
    padding-left: 8px;
  }

  .form-image {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 4px;
    margin-bottom: 0px;

    label {
      color: rgb(89 89 89);
    }
  }

  .form-text {
    display: flex;
    margin-top: 10px;
    gap: 2px;
  }
`;

export const FileNameEnums = {
  LEAD: 'AM-AD-BANNER-LEAD',
  APPLICANT: 'AM-AD-BANNER-APPLICANT',
  LEAD_APPLICANT: 'AM-AD-BANNER-LEAD-APPLICANT',
};
export const ScriptsEnums = {
  'EMPLOYEE-RECRUITMENT-INTERVIEW': 'Mitarbeitergewinnung Bewerbungsgespräch',
  'NEW-CUSTOMER-PRE-QUALIFICATION': 'Neukundengewinnung Vorqualifizierung',
  'NEW-CUSTOMER-CLOSSING-DISCUSSION': 'Neukundengewinnung Closinggespräch',
};

export const FileType = {
  image: 'image/*',
  pdf: 'application/pdf',
};
export default FormImageWrapper;
