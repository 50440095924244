import React, { FC, ReactChild, ReactChildren, useEffect, useState } from 'react';
import './inputSelect.scss';
import { InputGroup } from '../inputGroup/inputGroup';
import { Select } from 'antd';

interface InputSelectProps {
  dbField: string;
  label: string;
  placeholder: string;
  value: any;
  reset: boolean;
  noFloatStyle?: boolean;
  change: (keyField: string | undefined, value: string | undefined) => void;
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const InputSelect: FC<InputSelectProps> = ({
  value,
  dbField,
  label,
  placeholder,
  reset,
  change,
  children,
  noFloatStyle,
}) => {
  const [initValue, setInitValue] = useState<string>();

  useEffect(() => {
    if (value !== undefined) {
      setInitValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (reset) {
      setInitValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (initValue !== undefined) {
      if (value !== initValue) {
        change(dbField, initValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  return (
    <InputGroup label={label} noFloatStyle={noFloatStyle}>
      <Select
        style={{ width: '100%' }}
        onChange={(value) => setInitValue(value)}
        value={initValue === undefined ? '' : initValue}
        placeholder={placeholder}
      >
        {children}
      </Select>
    </InputGroup>
  );
};
