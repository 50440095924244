import React, { FC, ReactElement } from 'react';
import './spinner.scss';


export const Spinner: FC = (): ReactElement => {
  return (
    <div className="loader">
    </div>
  );
};
