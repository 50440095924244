import React, {FC, ReactChild, ReactChildren} from 'react';
import './inputGroup.scss';

interface InputGroupProps {
  label: string;
  noFloatStyle?: boolean;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const InputGroup: FC<InputGroupProps> = ({ label, children, noFloatStyle }) => {
  return(
    <div className="inputGroup" style={noFloatStyle ? {float: 'none'} : {}}>
      <div className="label">
        { label }
      </div>
      <div className="inputChildren">{ children }</div>
    </div>
  );
};
