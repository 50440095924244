import React, { FC, ReactChild, ReactChildren } from 'react';
import './row.scss';

interface RowProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  doSpacing?: Boolean;
}

export const Row: FC<RowProps> = ({ children, doSpacing = false }) => {
  return (
    <div style={{ marginBottom: doSpacing ? '10px' : '0px' }} className="row">
      {children}
    </div>
  );
};
