import React, { FC, ReactChild, ReactChildren } from 'react';
import './container.scss';

interface ContainerProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const Container: FC<ContainerProps> = ({ children }) => {
  return <div className="container">{children}</div>;
};
