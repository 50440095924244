import { differenceInDays } from 'date-fns';

export const mergeSheetsArrays = (allSheets, specificSheets, type, detailType) => {
  const map = new Map(specificSheets.map((item) => [item?.sheet?.id, item.value]));
  const newArray = allSheets
    .filter((item) => item.type === type && item.detailType === detailType)
    .map((item) => ({
      sheet: item,
      value: map.get(item.id) || '',
    }));
  return newArray;
};

export const isDateSevenDaysOld = (date) => {
  const currentDate = new Date();
  const targetDate = new Date(date);
  const daysDifference = differenceInDays(currentDate, targetDate);
  return daysDifference >= 7;
};
