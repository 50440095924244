import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.lead.fields.name'),
  },
  {
    name: 'firstName',
    label: i18n('entities.lead.fields.firstName'),
  },
  {
    name: 'lastName',
    label: i18n('entities.lead.fields.lastName'),
  },
  {
    name: 'lastContact',
    label: i18n('entities.lead.fields.lastContact'),
  },
  {
    name: 'followUp',
    label: i18n('entities.lead.fields.followUp'),
  },
  {
    name: 'dealStage',
    label: i18n('entities.lead.fields.dealStage'),
  },
  {
    name: 'company',
    label: i18n('entities.lead.fields.company'),
  },
  {
    name: 'street',
    label: i18n('entities.lead.fields.street'),
  },
  {
    name: 'country',
    label: i18n('entities.lead.fields.country'),
  },
  {
    name: 'postalCode',
    label: i18n('entities.lead.fields.postalCode'),
  },
  {
    name: 'city',
    label: i18n('entities.lead.fields.city'),
  },
  {
    name: 'phone',
    label: i18n('entities.lead.fields.phone'),
  },
  {
    name: 'email',
    label: i18n('entities.lead.fields.email'),
  },
  {
    name: 'product',
    label: i18n('entities.lead.fields.product'),
    render: exporterRenders.relationToOne('name'),
  },
  {
    name: 'owner',
    label: i18n('entities.lead.fields.owner'),
    render: exporterRenders.relationToOneOwner(),
  },
  {
    name: 'source',
    label: i18n('entities.lead.fields.source'),
  },
  {
    name: 'budget',
    label: i18n('entities.lead.fields.budget'),
  },
  {
    name: 'secondDecisionMaker',
    label: i18n('entities.lead.fields.secondDecisionMaker'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.lead.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.lead.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
