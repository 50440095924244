import React, { FC } from 'react';
import './card.scss';
import { QuestionOutlined } from '@ant-design/icons';
import { Card } from '../../../../base/card/card';
import {List} from "antd";
import CollapsableCard from 'src/components/base/collapsable/collapsable';

interface QuestionnaireCardProps {
  record: any;
}

export const QuestionnaireCard: FC<QuestionnaireCardProps> = ({ record }) => {
  return(
    <CollapsableCard title={'Fragebogen'} icon={<QuestionOutlined />} panelKey='applicant-question-card'>
      <List
        size="small"
        dataSource={record}
        renderItem={(item: any) => (
          <List.Item key={item.id}><List.Item.Meta title={item.question} description={item.answer}/></List.Item>
        )}
      />
    </CollapsableCard>
  );
};
