import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import applicantEnumerators from 'src/modules/applicant/applicantEnumerators';

export default [
  {
    name: 'firstName',
    label: i18n('entities.applicant.fields.firstName'),
    schema: schemas.string(i18n('entities.applicant.fields.firstName'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'lastName',
    label: i18n('entities.applicant.fields.lastName'),
    schema: schemas.string(i18n('entities.applicant.fields.lastName'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'sex',
    label: i18n('entities.applicant.fields.sex'),
    schema: schemas.enumerator(i18n('entities.applicant.fields.sex'), {
      options: applicantEnumerators.sex,
    }),
  },
  {
    name: 'phone',
    label: i18n('entities.applicant.fields.phone'),
    schema: schemas.string(i18n('entities.applicant.fields.phone'), {}),
  },
  {
    name: 'email',
    label: i18n('entities.applicant.fields.email'),
    schema: schemas.string(i18n('entities.applicant.fields.email'), {}),
  },
  {
    name: 'dealStage',
    label: i18n('entities.applicant.fields.dealStage'),
    schema: schemas.enumerator(i18n('entities.applicant.fields.dealStage'), {
      required: true,
      options: applicantEnumerators.dealStage,
    }),
  },
  {
    name: 'dateOfBirth',
    label: i18n('entities.applicant.fields.dateOfBirth'),
    schema: schemas.date(i18n('entities.applicant.fields.dateOfBirth'), {}),
  },
  // {
  //   name: 'followUp',
  //   label: i18n('entities.applicant.fields.followUp'),
  //   schema: schemas.date(i18n('entities.applicant.fields.followUp'), {}),
  // },
  {
    name: 'availableFrom',
    label: i18n('entities.applicant.fields.availableFrom'),
    schema: schemas.date(i18n('entities.applicant.fields.availableFrom'), {}),
  },
  {
    name: 'city',
    label: i18n('entities.applicant.fields.city'),
    schema: schemas.string(i18n('entities.applicant.fields.city'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'zipCode',
    label: i18n('entities.applicant.fields.zipCode'),
    schema: schemas.string(i18n('entities.applicant.fields.zipCode'), {
      min: 2,
      max: 255,
    }),
  },
  {
    name: 'job',
    label: i18n('entities.applicant.fields.job'),
    schema: schemas.relationToOne(i18n('entities.applicant.fields.job'), {}),
  },
  {
    name: 'salaryExpectations',
    label: i18n('entities.applicant.fields.salaryExpectations'),
    schema: schemas.integer(i18n('entities.applicant.fields.salaryExpectations'), {}),
  },
];
