import React, {FC, ReactChild, ReactChildren} from 'react';
import './col.scss';

interface ColProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const Col: FC<ColProps> = ({ children }) => {
  return(
    <div className="col">
      { children }
    </div>
  );
};
