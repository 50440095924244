import React, { FC } from 'react';
import './periodEnd.scss';
import { Container } from '../../base/layout/container/container';
import { Row } from '../../base/layout/row/row';

import logo from './logo-dark.png';
import { ReactComponent as Man } from './man-receipt.svg';
import { Button } from '../../base/button/button';

export const PeriodEnd: FC = () => {
  return (
    <Container>
      <Row>
        <div className="logo-header">
          <img src={logo} alt="Logo CC" />
        </div>
      </Row>
      <Row>
        <div className="periodEnd">
          <div className="periodEnd-message">
            <h1>Zugang wurde deaktiviert</h1>
            <p>
            Dieses Konto wurde deaktiviert. Falls du die Zusammenarbeit wieder starten möchtest, oder du glaubst, dass es sich um einen Fehler handelt, klicke bitte auf den Button unten und sende uns eine Nachricht.
            </p>
            <Row>
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = 'mailto:support@am-beratung.de';
                }}
              >
                Jetzt A&M Team kontaktieren
              </Button>
            </Row>
          </div>
          <div className="periodEnd-icon">{<Man />}</div>
        </div>
      </Row>
    </Container>
  );
};
