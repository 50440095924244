import React, { FC } from 'react';

import './dropDown.css';

interface props {
  showDropDown: boolean;
  initSelectionResult: any;
}

export const DropDown: FC<props> = ({ showDropDown, initSelectionResult }) => {
    console.log(initSelectionResult);
  if (showDropDown) {
    return (
      <div className="dropDown">
        <div>
          <p>Title</p>
        </div>
        <div>
          <ul>
            <li>1</li>
            <li>2</li>
          </ul>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
