const applicantEnumerators = {
  dealStage: [
    'application_received',
    'contact_attempt',
    'phone_interview',
    'interview',
    'screening',
    'offer',
    'hired',
    'not_suitable',
    'archived',
    'deleted',
  ],
  country: [
    'de',
    'at',
    'ch',
    'li',
  ],
  sex: [
    'male',
    'female',
    'divers',
  ],
  source: [
    'online',
    'direct',
    'other',
    'website',
    'google',
    'fair',
    'office',
    'sales_directly',
  ],
  not_suitable: [
    'disagree_on_the_conditions',
    'no_want_to_change',
    'no_longer_available',
    'no_fit_humanly',
    'no_fit_technically',
    'too_far_away',
  ]
};

export default applicantEnumerators;
