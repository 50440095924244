import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/sheet/importer/sheetImporterSelectors';
import SheetService from 'src/modules/sheet/sheetService';
import fields from 'src/modules/sheet/importer/sheetImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'PRODUCT_IMPORTER',
  selectors,
  SheetService.import,
  fields,
  i18n('entities.sheet.importer.fileName'),
);
