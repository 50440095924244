import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

const DetailsTable = ({ data }) => {

    const columns = [
        {
            title: 'Old',
            dataIndex: 'old',
            key: 'old',
        },
        {
            title: 'New',
            dataIndex: 'new',
            key: 'new',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={!data}
            pagination={false}
        />
    );
};

export default DetailsTable;
