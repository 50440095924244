import React, { FC, useEffect, useState } from 'react';
import { DropDown } from './dropDown/dropDown';
import { InputBox } from './inputBox/inputBox';

import './searchBar.css';

interface props {
  shortWidth?: boolean;
  returnText: (text: any) => void;
  onEnter?: (text: any) => void;
  initText?: string;
  placeholderText?: string;
  searchButtonText?: string;
  isDropDownVisible: boolean;
  initSelectionResult?: any;
  loading: boolean;
}

export const SearchBar: FC<props> = ({
  shortWidth = false,
  returnText,
  initText,
  isDropDownVisible,
  initSelectionResult,
  loading,
  onEnter,
  ...props
}) => {
  const [showDropDown, setShowDropDown] = useState(true);

  useEffect(() => {
    if (returnText.length > 0) {
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
  }, [returnText]);

  return (
    <div
      className="searchBar"
      style={{ width: shortWidth ? '250px' : '350px' }}
      onClick={() => {
        if (returnText.length > 0) {
          setShowDropDown(true);
        }
      }}
      onMouseLeave={() => {
        if (returnText.length > 0) {
          setShowDropDown(false);
        }
      }}
    >
      <InputBox onEnter={onEnter} returnText={returnText} initText={initText} loading={loading} {...props} />
      {isDropDownVisible ? <DropDown showDropDown={showDropDown} initSelectionResult={initSelectionResult} /> : <></>}
    </div>
  );
};
