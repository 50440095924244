import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.applicant.fields.id'),
  },
  {
    name: 'firstName',
    label: i18n('entities.applicant.fields.firstName'),
  },
  {
    name: 'lastName',
    label: i18n('entities.applicant.fields.lastName'),
  },
  {
    name: 'phone',
    label: i18n('entities.applicant.fields.phone'),
  },
  {
    name: 'email',
    label: i18n('entities.applicant.fields.email'),
  },
  {
    name: 'sex',
    label: i18n('entities.applicant.fields.sex'),
  },
  {
    name: 'followUp',
    label: i18n('entities.applicant.fields.followUp'),
  },
  {
    name: 'dealStage',
    label: i18n('entities.applicant.fields.dealStage'),
  },
  {
    name: 'dateOfBirth',
    label: i18n('entities.applicant.fields.dateOfBirth'),
  },
  {
    name: 'availableFrom',
    label: i18n('entities.applicant.fields.availableFrom'),
  },
  {
    name: 'city',
    label: i18n('entities.applicant.fields.city'),
  },
  {
    name: 'zipCode',
    label: i18n('entities.applicant.fields.zipCode'),
  },
  {
    name: 'job',
    label: i18n('entities.applicant.fields.job'),
    render: exporterRenders.relationToOneTitle(),
  },
  {
    name: 'salaryExpectations',
    label: i18n('entities.applicant.fields.salaryExpectations'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.applicant.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.applicant.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
