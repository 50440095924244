import authAxios from 'src/modules/shared/axios/authAxios';
// import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class SiteSettingsService {
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/site-settings`, body);

    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/site-settings`, body);

    return response.data;
  }

  static async find() {
    const response = await authAxios.get(`/site-settings`);

    return response.data;
  }

  static async delete(id) {
    const params = {
      id,
    };
    const response = await authAxios.delete(`/site-settings`, {
      params,
    });

    return response.data;
  }
}
