import { FileNameEnums, ScriptsEnums } from 'src/view/tenant/dashboard/FormImageWrapper';

export class SiteSettings {
  static get() {
    const data = localStorage.getItem('adBanners') || null;
    if (!data) {
      return;
    }
    return JSON.parse(data);
  }

  static set(data) {
    if (data) {
      localStorage.setItem('adBanners', JSON.stringify(data));
    }
  }

  static getByType(type) {
    const ads = this.get();
    if (!ads) {
      return;
    }
    return ads.find((d) => d.type === type);
  }

  static showAdByTenant(tenant) {
    if (tenant.isApplicant && tenant.isCustomer) {
      return this.getByType(FileNameEnums.LEAD_APPLICANT);
    }
    if (tenant.isApplicant) {
      return this.getByType(FileNameEnums.APPLICANT);
    }
    if (tenant.isCustomer) {
      return this.getByType(FileNameEnums.LEAD);
    }
    return;
  }

  static showScriptsByType(type) {
    return this.getByType(type);
  }
}
