import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('sheet.fields.id'),
  },
  {
    name: 'title',
    label: i18n('sheet.fields.title'),
  },
  {
    name: 'description',
    label: i18n('sheet.fields.description'),
  },
  {
    name: 'websiteUrl',
    label: i18n('sheet.fields.websiteUrl'),
  },
  {
    name: 'contactName',
    label: i18n('sheet.fields.contactName'),
  },
  {
    name: 'contactPhone',
    label: i18n('sheet.fields.contactPhone'),
  },
  {
    name: 'createdAt',
    label: i18n('sheet.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('sheet.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
