import {Layout as AntLayout} from 'antd';
import React, {useEffect} from 'react';
import {useIntercom} from 'react-use-intercom';
import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';
import LayoutWrapper from 'src/view/layout/styles/LayoutWrapper';
import {useRouteMatch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import authSelectors from '../../modules/auth/authSelectors';
import {Ban} from '../../components/auth/ban/ban';
import {PeriodEnd} from '../../components/auth/periodEnd/periodEnd';
// import tenantSelectors from '../../modules/tenant/tenantSelectors';
// import userSelectors from '../../modules/user/userSelectors';

const {Content} = AntLayout;

const Layout = (props) => {
  const roles = useSelector(authSelectors.selectRoles);
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUserNameOrEmailPrefix);
  const currentUserId = useSelector(authSelectors.selectCurrentUser);
  const currentTenantUser = useSelector(authSelectors.selectCurrentTenantUser);
  const email = useSelector(authSelectors.selectCurrentUserEmail);

  const match = useRouteMatch();
  const {boot} = useIntercom();

  const isAdmin = (roles) => {
    return roles.includes('admin') || roles.includes('superadmin')
  }

  const getIntercomRole = (roles) => {
    return isAdmin(roles) ? 'Admin' : 'Employee'
  }
  
  boot({
    name: currentUser,
    email: email,
    userId: currentUserId.id,
    customAttributes: {
      tenant: currentTenant.name,
      tenantID: currentTenant.id,
      role: getIntercomRole(currentTenantUser.roles)
    },
  });

  useEffect(() => {
    const woopra: any = window.woopra;
    woopra.identify({
      email: email,
      name: currentUser,
      company: currentTenant.name
    });
    woopra.track();
  });

  const roleCheck = roles.find((role) => role === 'superadmin');

  if (currentTenant.planStatus === 'deactivate') {
    if (roleCheck !== 'superadmin') {
      return <Ban/>;
    }
  }

  if (currentTenant.planStatus === 'cancel_at_period_end') {
    if (roleCheck !== 'superadmin') {
      return <PeriodEnd/>;
    }
  }

  return (
    <LayoutWrapper>
      <Menu url={match.url}/>

      <AntLayout>
        <Header/>

        <Content>{props.children}</Content>
      </AntLayout>
    </LayoutWrapper>
  );
};

export default Layout;
