import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/lead/importer/leadImporterSelectors';
import LeadService from 'src/modules/lead/leadService';
import fields from 'src/modules/lead/importer/leadImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'LEAD_IMPORTER',
  selectors,
  LeadService.import,
  fields,
  i18n('entities.lead.importer.fileName'),
);