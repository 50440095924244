import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import config from 'src/config';

import {
  CodeSandboxOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  FileSearchOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  // BankOutlined,
  ShopOutlined,
  UserAddOutlined,
  DatabaseOutlined
} from '@ant-design/icons';

const permissions = Permissions.values;

export default [
  {
    path: '/lead',
    exact: false,
    permissionRequired: null,
    icon: <ContainerOutlined />,
    label: i18n('entities.lead.menu'),
  },
  {
    path: '/applicant',
    exact: false,
    permissionRequired: null,
    icon: <UsergroupAddOutlined />,
    label: i18n('entities.applicant.menu'),
    // badge: <Badge count={i18n('common.new')} offset={[10, 0]} />,
    badge: null,
  },
  {
    path: '/tenant',
    permissionRequired: permissions.tenantRead,
    icon: <ShopOutlined />,
    label: 'Unternehmen',
  },
  {
    path: '/current-tenant',
    permissionRequired: permissions.currentTenantRead,
    icon: <ShopOutlined />,
    label: i18n('entities.currentTenant.menu'),
  },
  {
    path: '/product',
    permissionRequired: permissions.productRead,
    icon: <CodeSandboxOutlined />,
    label: i18n('entities.product.menu'),
  },
  
  {
    path: '/library',
    permissionRequired: permissions.driveRead,
    icon: <DatabaseOutlined />,
    label: i18n('drive.menu'),
  },

  

  {
    path: '/job',
    exact: false,
    permissionRequired: null,
    icon: <SearchOutlined />,
    label: i18n('entities.job.menu'),
  },

  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planRead,
    icon: <CreditCardOutlined />,
    label: i18n('plan.menu'),
  },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <UserAddOutlined />,
  },

  {
    path: '/dashboard',
    label: i18n('tenant.dashboard.menu'),
    exact: true,
    permissionRequired: permissions.tenantDashboard,
    icon: <FileSearchOutlined />,
  },

  {
    path: '/audit-logs',
    icon: <FileSearchOutlined />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },

  /*{
        path: '/supplier',
        icon: <BankOutlined />,
        label: i18n('supplier.menu'),
        permissionRequired: permissions.auditLogRead,
    },*/

  {
    path: '/settings',
    icon: <SettingOutlined />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/campaign',
    icon: <FileSearchOutlined />,
    label: i18n('campaign.menu'),
    permissionRequired: permissions.campaignRead,
  },

  {
    path: '/sheet',
    icon: <FileSearchOutlined />,
    label: i18n('sheet.menu'),
    permissionRequired: permissions.sheetRead,
  },

].filter(Boolean);
