import React, { FC, useCallback, useState } from 'react';
import { tenantSearch } from 'src/api/tenantSearch';

export interface CCContextData {
  context: {
    pages: [{ name: string; cache: { search: string } }];
    search: [
      { field: 'tenants'; data: any[], count?: number }, 
      { field: 'leads'; data: any[], count?: number }, 
      { field: 'all'; data: any[], count?: number },
      { field: 'applicants_leads'; data: any[], count?: number },
    ];
  };
  isLoading: boolean;
  fetchSearch: (text: string, collection: string) => void;
  removeSearch: (arrayId: number) => void;
}

export const test: CCContextData = {
  context: {
    pages: [{ name: '', cache: { search: '' } }],
    search: [
      { field: 'tenants', data: [] },
      { field: 'leads', data: [] },
      { field: 'all', data: [] },
      { field: 'applicants_leads', data: [] },
    ],
  },
  isLoading: false,
  fetchSearch: () => null,
  removeSearch: () => null,
};

export const CCContext = React.createContext<CCContextData>(test);

export const CCProvider: FC = ({ children }) => {
  const [context, setContext] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearch = useCallback(async (text: string, collection: string) => {
    setIsLoading(true);
    const result = await tenantSearch(text, collection)
    await setContext(Object.assign({}, {
      pages: [{ name: collection, cache: { search: text } }],
      search: result,
    }, context));
    setIsLoading(false);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [setContext],
  );

  const removeSearch = () => {
    console.log('removeSearch');
  };

  return <CCContext.Provider value={{ context, isLoading, fetchSearch, removeSearch }}>{children}</CCContext.Provider>;
};
