import actions from 'src/modules/userColumn/userColumnActions';

const initialData = {
  loading: false,
  data: {},
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS || type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      data: payload,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
};
