import TenantService from 'src/modules/tenant/tenantService';
import selectors from 'src/modules/tenant/list/tenantListSelectors';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'TENANT_LIST';

const actions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FETCH_AUTOCOMPLETE_STARTED: `${prefix}_FETCH_AUTOCOMPLETE_STARTED`,
  FETCH_AUTOCOMPLETE_SUCCESS: `${prefix}_FETCH_AUTOCOMPLETE_SUCCESS`,
  FETCH_AUTOCOMPLETE_ERROR: `${prefix}_FETCH_AUTOCOMPLETE_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  COUNT_CHANGED: `${prefix}_COUNT_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doChangeSelected(payload) {
    return {
      type: actions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: actions.RESETED,
    });

    dispatch(actions.doFetch());
  },

  doChangeCount: (count) => (dispatch) => {
    dispatch({
      type: actions.COUNT_CHANGED,
      payload: { count },
    });
  },

  doChangePaginationAndSort: (pagination, sorter) => async (dispatch, getState) => {
    dispatch({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch({
      type: actions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(actions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    dispatch(actions.doFetch(filter, true));
  },

  doFetch:
    (filter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.FETCH_STARTED,
          payload: { filter, keepPagination },
        });

        const response = await TenantService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: actions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FETCH_ERROR,
        });
      }
    },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.UPDATE_STARTED,
      });

      // const tenant = await TenantService.update(id, values);
      dispatch({
        type: actions.UPDATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.UPDATE_ERROR,
      });
    }
  },

  doAutocomplete: (query?, limit?) => async (dispatch) => {
    try {
      dispatch({
        type: actions.FETCH_AUTOCOMPLETE_STARTED,
      });

      const tenants = await TenantService.totalListAutocomplete(query, limit);

      dispatch({
        type: actions.FETCH_AUTOCOMPLETE_SUCCESS,
        payload: { tenants },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FETCH_AUTOCOMPLETE_ERROR,
      });
    }
  },
};

export default actions;
