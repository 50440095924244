const leadEnumerators = {
  dealStage: [
    'new',
    'contact_attempt',
    'appointment_agreed',
    'appointment_failed',
    'appointment_is_done',
    'offer_sent',
    'assignment',
    'not_suitable',
    'archived',
    'deleted',
  ],
  country: [
    'de',
    'at',
    'ch',
    'li',
  ],
  source: [
    'online',
    'direct',
    'other',
    'website',
    'google',
    'fair',
    'office',
    'sales_directly',

  ],
  not_suitable: [
    'too_little_budget',
    'no_longer_available',
    'bought_somewhere_else',
    'has_changed_his_mind',
    'another_product',
    'wrong_telephone',
    'request_too_far_away',
  ]
};

export default leadEnumerators;
