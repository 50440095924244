import React, { FC } from 'react';
import './ban.scss';
import { Container } from '../../base/layout/container/container';
import { Row } from '../../base/layout/row/row';

import logo from './logo-dark.png';
import { ReactComponent as Man } from './man-receipt.svg';
import { Button } from '../../base/button/button';

export const Ban: FC = () => {
  return (
    <Container>
      <Row>
        <div className="logo-header">
          <img src={logo} alt="Logo CC" />
        </div>
      </Row>
      <Row>
        <div className="ban">
          <div className="ban-message">
            <h1>Konto vorübergehend deaktiviert</h1>
            <p>
              Leider konnten wir bisher keinen Zahlungseingang unserer offenen Rechnungen verzeichnen. Aus diesem Grund
              mussten wir deinen ContactCloud Zugang vorübergehend deaktivieren. Bitte überweise den offenen Betrag
              schnellstmöglich, um das Konto zu aktivieren.
            </p>
            <p>
              Du kannst die offene Zahlung und auch weitere Zahlungen gerne bequem mit unserem Lastschrift-Service
              abwickeln. Klicke dafür einfach unten auf den Button.
            </p>
            <Row>
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = 'https://am-beratung.de/lastschrift';
                }}
              >
                Jetzt Rechnung per Lastschrift begleichen
              </Button>
            </Row>
          </div>
          <div className="ban-icon">{<Man />}</div>
        </div>
      </Row>
    </Container>
  );
};
