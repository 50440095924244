import { Form, Input } from 'antd';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import FormErrors from 'src/view/shared/form/formErrors';

export const InputFormItem = (props) => {
  const {
    label,
    name,
    hint,
    layout,
    size,
    type,
    placeholder,
    autoFocus,
    autoComplete,
    prefix,
    externalErrorMessage,
    required,
    addonAfter,
    disabled,
    isEmailSettingPage,
    min
  } = props;

  const {
    setValue,
    watch,
    register,
    errors,
    formState: { touched, isSubmitted },
  } = useFormContext();

  useEffect(() => {
    register({ name });
  }, [register, name]);

  const errorMessage = FormErrors.errorMessage(name, errors, touched, isSubmitted, externalErrorMessage);
  const formItemStyle = isEmailSettingPage && { marginBottom: '0px' }
  return (
    <Form.Item
      {...layout}
      label={label}
      style={formItemStyle}
      required={required}
      validateStatus={errorMessage ? 'error' : 'success'}
      help={errorMessage || hint}
    >
      <Input
        id={name}
        name={name}
        type={type}
        value={watch(name)}
        onChange={(event) => {
          setValue(name, event.target.value, { shouldValidate: true });
          props.onChange && props.onChange(event.target.value);
        }}
        onBlur={(event) => {
          props.onBlur && props.onBlur(event);
        }}
        size={size || undefined}
        placeholder={placeholder || undefined}
        autoFocus={autoFocus || false}
        autoComplete={autoComplete || undefined}
        prefix={prefix || undefined}
        addonAfter={addonAfter || undefined}
        disabled={disabled || undefined}
        min={min}
      />
    </Form.Item>
  );
};

InputFormItem.defaultProps = {
  layout: null,
  type: 'text',
  required: false,
  isEmailSettingPage: false,
  min: 0,

};

InputFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  layout: PropTypes.object,
  errorMessage: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  externalErrorMessage: PropTypes.string,
  addonAfter: PropTypes.any,
  disabled: PropTypes.any,
  isEmailSettingPage: PropTypes.bool,
};

export default InputFormItem;
