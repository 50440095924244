import {
  LockOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
  UserOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { i18n } from 'src/i18n';
import authActions from 'src/modules/auth/authActions';
import authSelectors from 'src/modules/auth/authSelectors';
import layoutActions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import { getHistory } from 'src/modules/store';
// import I18nSelect from 'src/view/layout/I18nSelect';
import HeaderWrapper from 'src/view/layout/styles/HeaderWrapper';
import config from 'src/config';
import { SearchBar } from 'src/components/base/searchBar/searchBar';
import { CCContext } from 'src/context/ContactCloudContext';
import QRCode from './QRCode';

const { Header: AntHeader } = Layout;

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { isLoading } = useContext(CCContext);
  // const [initSelectionResult, setInitSelectionResult] = useState<any>([] as any[]);
  const [text, setText] = useState<string>('');

  // useEffect(() => {
  //     if (undefined !== context.search && context.search.length) {
  //         context.search.map((search) => {
  //             if(search.field === 'all') {
  //                 if (search.hasOwnProperty('data')) {
  //                     if (search.data.length > 0) {
  //                         console.log(search);
  //                     }
  //                 }
  //             }
  //         });
  //     }
  // }, [context])

  // useEffect(() => {
  //     if(text.length > 0) {
  //         if (undefined !== context.pages && context.pages.length) {
  //             context.pages.map((pages) => {
  //                 if(pages.name === 'all') {
  //                     if (pages.cache.hasOwnProperty('search')) {
  //                         if (text === '' && pages.cache.search.length > 1) {
  //                             setText(pages.cache.search);
  //                         }

  //                         if(pages.cache.search !== text) {
  //                             fetchSearch(text, 'all');
  //                         }
  //                     }
  //                 }
  //             });
  //         } else {
  //             fetchSearch(text, 'all');
  //         }
  //     }
  // }, [text, context]);

  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const userDropdownText = useSelector(authSelectors.selectCurrentUserNameOrEmailPrefix);
  const userDropdownAvatar = useSelector(authSelectors.selectCurrentUserAvatar);

  const doSignout = () => {
    dispatch(authActions.doSignout());
  };

  const doNavigateToProfile = () => {
    getHistory().push('/profile');
  };

  const doNavigateToIntegration = () => {
    getHistory().push('/integration');
  };

  const doNavigateToEmailSettings = () => {
    getHistory().push('/email-settings');
  };

  const doNavigateToPasswordChange = () => {
    getHistory().push('/password-change');
  };

  const doNavigateToTenants = () => {
    getHistory().push('/tenant');
  };

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const doSearchEnter = (text) => {
    console.log('000000000000', text);
    history.push('/search?query=' + text);
  };

  useEffect(() => {
    if (location.search) {
      const text = new URLSearchParams(location.search).get('query');
      if (text) setText(text);
    }
  }, [location.search]);

  const userMenu = (
    <Menu selectedKeys={[]}>
      <Menu.Item onClick={doNavigateToProfile} key="userCenter">
        <UserOutlined />
        {i18n('auth.profile.title')}
      </Menu.Item>
      <Menu.Item onClick={doNavigateToIntegration} key="userIntegration">
        <SettingOutlined />
        {i18n('auth.integration.title')}
      </Menu.Item>
      <Menu.Item onClick={doNavigateToEmailSettings} key="emailSettings">
        <SettingOutlined />
        {i18n('entities.emailSettings.menu')}
      </Menu.Item>
      <Menu.Item onClick={doNavigateToPasswordChange} key="passwordChange">
        <LockOutlined />
        {i18n('auth.passwordChange.title')}
      </Menu.Item>
      {['multi', 'multi-with-subdomain'].includes(config.tenantMode) && (
        <Menu.Item onClick={doNavigateToTenants} key="tenants">
          <AppstoreOutlined />
          {i18n('auth.tenants')}
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item onClick={doSignout} key="logout">
        <LogoutOutlined />
        {i18n('auth.signout')}
      </Menu.Item>
    </Menu>
  );

  // @ts-ignore
  return (
    <HeaderWrapper>
      <AntHeader>
        {menuVisible ? (
          <MenuFoldOutlined className="trigger" onClick={doToggleMenu} />
        ) : (
          <MenuUnfoldOutlined className="trigger" onClick={doToggleMenu} />
        )}

        <div style={{ alignSelf: 'center', flex: 1 }}>
          <SearchBar
            isDropDownVisible={false}
            onEnter={doSearchEnter}
            returnText={setText}
            initText={text}
            placeholderText={i18n('search.placeholder')}
            searchButtonText={i18n('search.button')}
            loading={isLoading}
          />
        </div>

        <div className="header-right">
          <span className="i18n-select">
            <QRCode />
            {/* <I18nSelect /> */}
          </span>
          <Dropdown className="user-dropdown" overlay={userMenu} trigger={['click']}>
            <div className="user-dropdown-content">
              <Avatar
                className="user-dropdown-avatar"
                size="small"
                src={userDropdownAvatar || undefined}
                alt="avatar"
                icon={userDropdownAvatar ? undefined : <UserOutlined />}
              />
              <span className="user-dropdown-text">
                <span>{userDropdownText}</span>{' '}
                {['multi', 'multi-with-subdomain'].includes(config.tenantMode) && (
                  <span className="user-dropdown-text-tenant">{currentTenant && currentTenant.name}</span>
                )}
              </span>
            </div>
          </Dropdown>
        </div>
      </AntHeader>
    </HeaderWrapper>
  );
};

export default Header;
