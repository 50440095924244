import React, { useState } from 'react';
import { Modal } from 'antd';
import { i18n } from 'src/i18n';
import SheetForm from 'src/view/sheet/form/SheetForm';
import SheetService from 'src/modules/sheet/sheetService';
import Errors from 'src/modules/shared/error/errors';

const SheetFormModal = (props) => {
  const [saveLoading, setSaveLoading] = useState(false);

  const doSubmit = async (_, data) => {
    data.detailType = props?.record?.detailType;
    try {
      setSaveLoading(true);
      var id = '';
      if (props.record && props.record.id) {
        id = props.record.id;
        await SheetService.update(id, data);
      } else {
        var _record = await SheetService.create(data);
        id = _record.id;
      }
      const record = await SheetService.find(id);
      props.onSuccess(record);
    } catch (error) {
      Errors.handle(error);
    } finally {
      setSaveLoading(false);
    }
  };

  if (!props.visible) {
    return null;
  }

  return (
    <Modal title={i18n('sheet.new.title')} visible={props.visible} onCancel={() => props.onCancel()} footer={false}>
      <SheetForm
        saveLoading={saveLoading}
        onSubmit={doSubmit}
        onCancel={props.onCancel}
        record={props.record}
        modal
        isTypedisabled={props.isTypedisabled}
      />
    </Modal>
  );
};

export default SheetFormModal;
