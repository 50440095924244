import React, { FC } from 'react';
import { Spinner } from '../../spinner/spinner';
import './spinner.scss';

export const DrawerSpinner: FC = () => {
  return (
    <div className="spinner-wrapper">
      <div className="spinner-body">
        <Spinner />
        <p>Bitte warten</p>
      </div>
    </div>
  );
};
