import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/job/importer/jobImporterSelectors';
import JobService from 'src/modules/job/jobService';
import fields from 'src/modules/job/importer/jobImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'PRODUCT_IMPORTER',
  selectors,
  JobService.import,
  fields,
  i18n('entities.job.importer.fileName'),
);
